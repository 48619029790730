import React from 'react'
import styled from 'styled-components'

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  height: 21px;
  width: 21px;
  border: 2px solid ${props => props.theme.colors.casper};
  transition: background-color ${props => props.theme.speeds.fast},
    border-color ${props => props.theme.speeds.fast};
  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 2px;
    left: 7px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 4px 0;
    transform: rotate(45deg);
  }
`

const Label = styled.label`
  display: block;
  position: relative;
  min-height: 25px;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ ${Checkmark} {
        background-color: ${props => props.theme.colors.gunmetal};
        border-color: ${props => props.theme.colors.gunmetal};
        &:after {
          display: block;
        }
      }
    }
  }
  &:hover {
    input {
      ~ ${Checkmark} {
        background-color: ${props => props.theme.colors.casper};
        border-color: ${props => props.theme.colors.casper};
      }
      &:checked ~ ${Checkmark} {
        background-color: ${props => props.theme.colors.gunmetal};
        border-color: ${props => props.theme.colors.gunmetal};
      }
    }
  }
`
const CheckboxLabel = props => {
  const { labelText = 'Check ' } = props
  return (
    <Label>
      {props.children}
      <Checkmark />
    </Label>
  )
}

export default CheckboxLabel
