import React from 'react'

import { presentDollarAmount } from '../../../helpers/presenters'
import * as AppUI from '../../app'
import Grid2Columns from '../../layouts/Grid2Columns'
import * as UI from '../styled-components'
import Section from './section'

const tabName = 'NON_JOINT_CHILDREN_ADJUSTMENTS'
const tabLabel =  'Non-Joint Children Adjustments'
const NonJointChildrenAdjustments = props => {
  const {
    toggleActiveTab,
    activeTab,
    party1Label,
    party2Label,
    PARTY_1,
    PARTY_2,
    adjustments1,
    adjustments2,
    childSupportNOTREntry,
    dispatch
  } = props
  return (
    <Section {...{tabName, tabLabel, toggleActiveTab, activeTab}}>
      <UI.BodySectionInner>
        <UI.BodySubSectionTitle>Credits for Non-Joint Children</UI.BodySubSectionTitle>
        <AppUI.Input>
          <AppUI.RadioLabel>Not applicable
            <input
              name="childSupportNOTREntry"
              value="false"
              type="radio"
              checked={!childSupportNOTREntry}
              onChange={e => {
                dispatch({
                  type: 'SET_CHILD_SUPPORT_NOTR_ENTRY',
                  entry: e.target.value
                })
              }}/>
            <AppUI.RadioCheckmark className="app-checkmark" />
          </AppUI.RadioLabel>
          <AppUI.RadioLabel>Enter child support for children <strong>not</strong> of this relationship
            <input
              name="childSupportNOTREntry"
              value="true"
              type="radio"
              checked={childSupportNOTREntry}
              onChange={e => {
                dispatch({
                  type: 'SET_CHILD_SUPPORT_NOTR_ENTRY',
                  entry: e.target.value
                })
              }}/>
            <AppUI.RadioCheckmark className="app-checkmark" />
          </AppUI.RadioLabel>
        </AppUI.Input>
        {childSupportNOTREntry && (
          <div>
            <UI.BodyInstructions>Enter the <strong>court-ordered</strong> child support payments made by each parent for children not of this relationship pursuant to §14-10-115(6)(a)(I), C.R.S, if any.</UI.BodyInstructions>
            <Grid2Columns>
              <AppUI.Input>
                <label>{party1Label}</label>
                <input
                  type="number"
                  value={adjustments1.supportPaidChildrenNOTR}
                  onChange={e => {
                    const newSupportPaid = '' === e.target.value ? false : Number(e.target.value)
                    dispatch({
                      type: 'SET_ADJUSTMENTS',
                      party: PARTY_1,
                      update: { supportPaidChildrenNOTR: newSupportPaid }
                    })
                  }}
                />
              </AppUI.Input>
              <AppUI.Input>
                <label>{party2Label}</label>
                <input
                  type="number"
                  value={adjustments2.supportPaidChildrenNOTR}
                  onChange={e => {
                    const newSupportPaid = '' === e.target.value ? false : Number(e.target.value)
                    dispatch({
                      type: 'SET_ADJUSTMENTS',
                      party: PARTY_2,
                      update: { supportPaidChildrenNOTR: newSupportPaid }
                    })
                  }}
                />
              </AppUI.Input>
            </Grid2Columns>
            <br />
            <UI.BodyInstructions>Number of minor children not of this relationship <strong>living primarily</strong> with each parent, if any. See C.R.S. § 14-10-115(6)(a)(II).</UI.BodyInstructions>
            <Grid2Columns>
              <AppUI.Input>
                <label>{party1Label}</label>
                <input
                  type="number"
                  value={adjustments1.numberOfChildrenOtherRelationshipPrimaryLiving}
                  min="0"
                  step="1"
                  onChange={e => {
                    const newNumberOfChildren = '' === e.target.value ? false : Number(e.target.value)
                    dispatch({
                      type: 'SET_ADJUSTMENTS',
                      party: PARTY_1,
                      update: { numberOfChildrenOtherRelationshipPrimaryLiving: newNumberOfChildren }
                    })
                  }}
                />
              </AppUI.Input>
              <AppUI.Input>
                <label>{party2Label}</label>
                <input
                  type="number"
                  value={adjustments2.numberOfChildrenOtherRelationshipPrimaryLiving}
                  min="0"
                  step="1"
                  onChange={e => {
                    const newNumberOfChildren = '' === e.target.value ? false : Number(e.target.value)
                    dispatch({
                      type: 'SET_ADJUSTMENTS',
                      party: PARTY_2,
                      update: { numberOfChildrenOtherRelationshipPrimaryLiving: newNumberOfChildren }
                    })
                  }}
                />
              </AppUI.Input>
            </Grid2Columns>
            {(adjustments1.numberOfChildrenOtherRelationshipPrimaryLiving ||
              adjustments2.numberOfChildrenOtherRelationshipPrimaryLiving) && (
              <div>
                <UI.BodyInstructions>Based on the monthly gross incomes and number of minor children not of this relationship living primarily with each parent, the credits pursuant to §14-10-115(6)(b)(II), C.R.S are:</UI.BodyInstructions>
                <Grid2Columns>
                  <AppUI.Input>
                    <label>{party1Label}</label>
                    <UI.BodyValue>{adjustments1.otherRelationshipResponsibility ?
                        presentDollarAmount(adjustments1.otherRelationshipResponsibility)
                        : 'N/A'}</UI.BodyValue>
                  </AppUI.Input>
                  <AppUI.Input>
                    <label>{party2Label}</label>
                    <UI.BodyValue>{adjustments2.otherRelationshipResponsibility ?
                        presentDollarAmount(adjustments2.otherRelationshipResponsibility)
                      : 'N/A'}</UI.BodyValue>
                  </AppUI.Input>
                </Grid2Columns>
              </div>
            )}
            <br />
            <UI.BodyInstructions>Amount of <strong>documented money payments</strong> actually paid for the support of children not of this relationship who do not live primarily with each parent pursuant to §14-10-115(6)(a)(III), C.R.S.</UI.BodyInstructions>
            <Grid2Columns>
              <AppUI.Input>
                <label>{party1Label}</label>
                <input
                  type="number"
                  value={adjustments1.documentedPaymentsChildrenOtherRelationship}
                  onChange={e => {
                    const newPayments = '' === e.target.value ? false : Number(e.target.value)
                    dispatch({
                      type: 'SET_ADJUSTMENTS',
                      party: PARTY_1,
                      update: { documentedPaymentsChildrenOtherRelationship: newPayments }
                    })
                  }}
                />
              </AppUI.Input>
              <AppUI.Input>
                <label>{party2Label}</label>
                <input
                  type="number"
                  value={adjustments2.documentedPaymentsChildrenOtherRelationship}
                  onChange={e => {
                    const newPayments = '' === e.target.value ? false : Number(e.target.value)
                    dispatch({
                      type: 'SET_ADJUSTMENTS',
                      party: PARTY_2,
                      update: { documentedPaymentsChildrenOtherRelationship: newPayments }
                    })
                  }}
                />
              </AppUI.Input>
            </Grid2Columns>
            <UI.BodyInstructions>Enter the number of minor children not of this relationship not living primarily with each parent, if any.</UI.BodyInstructions>
            <Grid2Columns>
              <AppUI.Input>
                <label>{party1Label}</label>
                <input
                  type="number"
                  value={adjustments1.numberOfChildrenOtherRelationshipNotPrimaryLiving}
                  min="0"
                  step="1"
                  onChange={e => {
                    const newNumberOfChildren = '' === e.target.value ? false : Number(e.target.value)
                    dispatch({
                      type: 'SET_ADJUSTMENTS',
                      party: PARTY_1,
                      update: { numberOfChildrenOtherRelationshipNotPrimaryLiving: newNumberOfChildren }
                    })
                  }}
                />
              </AppUI.Input>
              <AppUI.Input>
                <label>{party2Label}</label>
                <input
                  type="number"
                  value={adjustments2.numberOfChildrenOtherRelationshipNotPrimaryLiving}
                  min="0"
                  step="1"
                  onChange={e => {
                    const newNumberOfChildren = '' === e.target.value ? false : Number(e.target.value)
                    dispatch({
                      type: 'SET_ADJUSTMENTS',
                      party: PARTY_2,
                      update: { numberOfChildrenOtherRelationshipNotPrimaryLiving: newNumberOfChildren }
                    })
                  }}
                />
              </AppUI.Input>
            </Grid2Columns>
          </div>
        )}
      </UI.BodySectionInner>
    </Section>
  )
}

export default NonJointChildrenAdjustments
