import React from 'react'
import * as UI from '../styled-components'
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'

// TODO: change tabName & tabLabel to key & other?
const Section = props => {
  const {
    tabName,
    tabLabel,
    toggleActiveTab,
    activeTab
  } = props
  const isActive = tabName === activeTab
  const onClick = () => toggleActiveTab(tabName)
  const icon = isActive ? faAngleDown : faAngleRight
  return (
    <div>
      <UI.ToggleSectionButton {...{ isActive, onClick }}>
        <UI.ToggleSectionIcon {...{icon}} /> {tabLabel}
      </UI.ToggleSectionButton>
      {isActive && props.children}
    </div>
  )
}

export default Section
