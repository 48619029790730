import React from 'react'

import * as AppUI from '../../app'
import Label from '../../inputs/label'
import Grid2Columns from '../../layouts/Grid2Columns'
import * as UI from '../styled-components'
import Section from './section'

const tabName = 'CHILDREN'
const tabLabel =  'Children'
const Children = props => {
  const {
    toggleActiveTab,
    activeTab,
    numberOfChildren,
    numberOfChildrenInput,
    dispatch
  } = props
  return (
    <Section {...{tabName, tabLabel, toggleActiveTab, activeTab}}>
      <UI.BodySectionInner>
        <AppUI.Input>
          <Label>Number of children subject to this order:</Label>
          <Grid2Columns>
            <input
              type="number"
              min="1"
              max="6"
              value={numberOfChildrenInput}
              onChange={e => {
                dispatch({
                  type: 'SET_NUMBER_OF_CHILDREN',
                  value: e.target.value
                })
              }}
            />
          </Grid2Columns>
        </AppUI.Input>
        {(6 <= numberOfChildren) &&
            <UI.BodyInstructions><em>Because Colorado’s Child Support Guidelines do not account for greater than six children, this calculation will calculate child support for up to six children only. C.R.S. § 14-10-115(7)(b).</em></UI.BodyInstructions>
        }
      </UI.BodySectionInner>
    </Section>
  )
}
export default Children
