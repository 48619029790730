import React from 'react'

import * as AppUI from '../../app'
import Grid2Columns from '../../layouts/Grid2Columns'
import * as UI from '../styled-components'
import Section from './section'

const tabName = 'OVERNIGHTS'
const tabLabel = 'Overnights'

const Overnights = props => {
  const {
    toggleActiveTab,
    activeTab,
    party1Label,
    party2Label,
    PARTY_1,
    PARTY_2,
    overnightsInput,
    overnightsParty,
    overnightWidget,
    dispatch
  } = props
  return (
    <Section {...{tabName, tabLabel, toggleActiveTab, activeTab}}>
      <UI.BodySectionInner>
        {/*<AppUI.Input>
          <label htmlFor="overnightWidget">Select how to enter overnights</label>
          <AppUI.RadioLabel>Enter overnights directly
            <input
              name="overnightWidget"
              value="DIRECT"
              type="radio"
              checked={'DIRECT' === overnightWidget}
              onChange={e => {
                dispatch({
                  type: 'SET_OVERNIGHTS_WIDGET',
                  widget: e.target.value
                })
              }}/>
            <AppUI.RadioCheckmark className="app-checkmark" />
          </AppUI.RadioLabel>
          <AppUI.RadioLabel>Use calculator to enter number of overnights
            <input
              name="overnightWidget"
              value="CALCULATOR"
              type="radio"
              checked={'CALCULATOR' === overnightWidget}
              onChange={e => {
                dispatch({
                  type: 'SET_OVERNIGHTS_WIDGET',
                  widget: e.target.value
                })
              }}/>
            <AppUI.RadioCheckmark className="app-checkmark" />
          </AppUI.RadioLabel>
        </AppUI.Input>*/}
        {'DIRECT' === overnightWidget && (
            <AppUI.Input>
              <label>Number of overnights:</label>
              <Grid2Columns>
                <input
                  type="number"
                  min="0"
                  max="365"
                  value={overnightsInput}
                  onChange={e => {
                    dispatch({
                      type: 'SET_OVERNIGHTS_INPUT',
                      value: e.target.value
                    })
                  }}
                />
              </Grid2Columns>
            </AppUI.Input>
        )}
        <AppUI.Input>
          <label>Spent with:</label>
          <AppUI.RadioLabel>{party1Label}
            <input
              name="overnightsParty"
              value="PARTY_1"
              type="radio"
              checked={PARTY_1 === overnightsParty}
              onChange={e => {
                dispatch({
                  type: 'SET_OVERNIGHTS_PARTY',
                  party: e.target.value
                })
              }}/>
            <AppUI.RadioCheckmark className="app-checkmark" />
          </AppUI.RadioLabel>
          <AppUI.RadioLabel>{party2Label}
            <input
              name="overnightsParty"
              value="PARTY_2"
              type="radio"
              checked={PARTY_2 === overnightsParty}
              onChange={e => {
                dispatch({
                  type: 'SET_OVERNIGHTS_PARTY',
                  party: e.target.value
                })
              }}/>
            <AppUI.RadioCheckmark className="app-checkmark" />
          </AppUI.RadioLabel>
        </AppUI.Input>
        {'OVERNIGHTS' === activeTab &&
          'CALCULATOR' === overnightWidget && (
            <UI.BodySectionInner>
              <OvernightsCalculator />
            </UI.BodySectionInner>
        )}
      </UI.BodySectionInner>
    </Section>
  )
}
export default Overnights
