import Wrapper from './wrapper'
import Label from './label'
import Text, { SmallerText } from './text'
import Email from './email'
import CheckboxLabel from './checkboxLabel'
import RadioButton from './radioButton'
import RadioButtonCheckmark from './radioSpan'
import SubmitWrapper from'./submitWrapper'
import PromoCodeWrapper from'./promoCodeWrapper'
import Select from './select'
import Stripe from './stripe'
import Password from './password'

const Inputs = {
  Wrapper,
  Label,
  Text,
  SmallerText,
  Email,
  CheckboxLabel,
  RadioButton,
  RadioButtonCheckmark,
  Select,
  SubmitWrapper,
  Stripe,
  PromoCodeWrapper,
  Password
}

export default Inputs
