export const csvDataString = `monthsOfMarriage,percentage,result,guidelineTermOfMaintenance
36,31.00%,11.16,11
37,31.17%,11.53,12
38,31.33%,11.91,12
39,31.50%,12.29,12
40,31.67%,12.67,13
41,31.83%,13.05,13
42,32.00%,13.44,13
43,32.17%,13.83,14
44,32.33%,14.23,14
45,32.50%,14.63,15
46,32.67%,15.03,15
47,32.83%,15.43,15
48,33.00%,15.84,16
49,33.17%,16.25,16
50,33.33%,16.67,17
51,33.50%,17.09,17
52,33.67%,17.51,18
53,33.83%,17.93,18
54,34.00%,18.36,18
55,34.17%,18.79,19
56,34.33%,19.23,19
57,34.50%,19.67,20
58,34.67%,20.11,20
59,34.83%,20.55,21
60,35.00%,21.00,21
61,35.17%,21.45,22
62,35.33%,21.91,22
63,35.50%,22.37,22
64,35.67%,22.83,23
65,35.83%,23.29,23
66,36.00%,23.76,24
67,36.17%,24.23,24
68,36.33%,24.71,25
69,36.50%,25.19,25
70,36.67%,25.67,26
71,36.83%,26.15,26
72,37.00%,26.64,27
73,37.17%,27.13,27
74,37.33%,27.63,28
75,37.50%,28.13,28
76,37.67%,28.63,29
77,37.83%,29.13,29
78,38.00%,29.64,30
79,38.17%,30.15,30
80,38.33%,30.67,31
81,38.50%,31.19,31
82,38.67%,31.71,32
83,38.83%,32.23,32
84,39.00%,32.76,33
85,39.17%,33.29,33
86,39.33%,33.83,34
87,39.50%,34.37,34
88,39.67%,34.91,35
89,39.83%,35.45,36
90,40.00%,36.00,36
91,40.17%,36.55,37
92,40.33%,37.11,37
93,40.50%,37.67,38
94,40.67%,38.23,38
95,40.83%,38.79,39
96,41.00%,39.36,39
97,41.17%,39.93,40
98,41.33%,40.51,41
99,41.50%,41.09,41
100,41.67%,41.67,42
101,41.83%,42.25,42
102,42.00%,42.84,43
103,42.17%,43.43,43
104,42.33%,44.03,44
105,42.50%,44.63,45
106,42.67%,45.23,45
107,42.83%,45.83,46
108,43.00%,46.44,46
109,43.17%,47.05,47
110,43.33%,47.67,48
111,43.50%,48.29,48
112,43.67%,48.91,49
113,43.83%,49.53,50
114,44.00%,50.16,50
115,44.17%,50.79,51
116,44.33%,51.43,51
117,44.50%,52.07,52
118,44.67%,52.71,53
119,44.83%,53.35,53
120,45.00%,54.00,54
121,45.17%,54.65,55
122,45.33%,55.31,55
123,45.50%,55.97,56
124,45.67%,56.63,57
125,45.83%,57.29,57
126,46.00%,57.96,58
127,46.17%,58.63,59
128,46.33%,59.31,59
129,46.50%,59.98,60
130,46.67%,60.67,61
131,46.83%,61.35,61
132,47.00%,62.04,62
133,47.17%,62.73,63
134,47.33%,63.43,63
135,47.50%,64.12,64
136,47.67%,64.83,65
137,47.83%,65.53,66
138,48.00%,66.24,66
139,48.17%,66.95,67
140,48.33%,67.67,68
141,48.50%,68.38,68
142,48.67%,69.11,69
143,48.83%,69.83,70
144,49.00%,70.56,71
145,49.17%,71.29,71
146,49.33%,72.03,72
147,49.50%,72.76,73
148,49.67%,73.51,74
149,49.83%,74.25,74
150,50.00%,75.00,75
151,50.00%,75.50,76
152,50.00%,76.00,76
153,50.00%,76.50,77
154,50.00%,77.00,77
155,50.00%,77.50,78
156,50.00%,78.00,78
157,50.00%,78.50,79
158,50.00%,79.00,79
159,50.00%,79.50,80
160,50.00%,80.00,80
161,50.00%,80.50,81
162,50.00%,81.00,81
163,50.00%,81.50,82
164,50.00%,82.00,82
165,50.00%,82.50,83
166,50.00%,83.00,83
167,50.00%,83.50,84
168,50.00%,84.00,84
169,50.00%,84.50,85
170,50.00%,85.00,85
171,50.00%,85.50,86
172,50.00%,86.00,86
173,50.00%,86.50,87
174,50.00%,87.00,87
175,50.00%,87.50,88
176,50.00%,88.00,88
177,50.00%,88.50,89
178,50.00%,89.00,89
179,50.00%,89.50,90
180,50.00%,90.00,90
181,50.00%,90.50,91
182,50.00%,91.00,91
183,50.00%,91.50,92
184,50.00%,92.00,92
185,50.00%,92.50,93
186,50.00%,93.00,93
187,50.00%,93.50,94
188,50.00%,94.00,94
189,50.00%,94.50,95
190,50.00%,95.00,95
191,50.00%,95.50,96
192,50.00%,96.00,96
193,50.00%,96.50,97
194,50.00%,97.00,97
195,50.00%,97.50,98
196,50.00%,98.00,98
197,50.00%,98.50,99
198,50.00%,99.00,99
199,50.00%,99.50,100
200,50.00%,100.00,100
201,50.00%,100.50,101
202,50.00%,101.00,101
203,50.00%,101.50,102
204,50.00%,102.00,102
205,50.00%,102.50,103
206,50.00%,103.00,103
207,50.00%,103.50,104
208,50.00%,104.00,104
209,50.00%,104.50,105
210,50.00%,105.00,105
211,50.00%,105.50,106
212,50.00%,106.00,106
213,50.00%,106.50,107
214,50.00%,107.00,107
215,50.00%,107.50,108
216,50.00%,108.00,108
217,50.00%,108.50,109
218,50.00%,109.00,109
219,50.00%,109.50,110
220,50.00%,110.00,110
221,50.00%,110.50,111
222,50.00%,111.00,111
223,50.00%,111.50,112
224,50.00%,112.00,112
225,50.00%,112.50,113
226,50.00%,113.00,113
227,50.00%,113.50,114
228,50.00%,114.00,114
229,50.00%,114.50,115
230,50.00%,115.00,115
231,50.00%,115.50,116
232,50.00%,116.00,116
233,50.00%,116.50,117
234,50.00%,117.00,117
235,50.00%,117.50,118
236,50.00%,118.00,118
237,50.00%,118.50,119
238,50.00%,119.00,119
239,50.00%,119.50,120
240,50.00%,120.00,120
`
