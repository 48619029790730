import { useReducer, useEffect } from 'react'
import { useSession } from '../App'
import { fetchWithAuth } from './useAuth'
const initialState = {
  loading: false,
  status: 'initialize',
  cases: [],
  messages: null,
  error: null
}

const reducer = (state, action) => {
  const { type, payload } = action
  //console.log(' - useCase - action - ', action)
  return Object.assign({}, state, payload)
}

export const bindUpdateCaseProfile = dispatch => (caseProfileDetails, caseId) => {
    dispatch({payload: {loading: true, status: 'updatingcaseprofile'}})
    const options = {
      method: 'PATCH',
      body: JSON.stringify(caseProfileDetails),
    }
    return fetchWithAuth(`/api/v1/my/cases/${caseId}`, options)
      .then(res => {
        dispatch({
          payload: {
            loading: false,
            status: 'loaded'
          }
        })
        return res
      })
      .catch(err => {
        dispatch({
          payload: {
            loading: false,
            status: 'error',
            error: err
          }
        })
        return err
      })
  }

function useCases() {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { status } = state
  const loadCases = success => {
    dispatch({ type: 'loading', payload: { loading: true, status: 'loading' }})
    return fetchWithAuth('/api/v1/my/cases')
      .then(responseData => {
        dispatch({
          type: 'loaded',
          payload: {
            loading: false,
            status: 'loaded',
            cases: responseData
          }
        })
        if (success) success()
      })
      .catch(error => {
        dispatch({ type: 'error', payload: { error: error, status: 'error' }})
      })
  }
  useEffect(() => {
      if ('initialize' === status) loadCases()
    }, [status])
  const createCase = caseName => {
    const options = {
      method: 'POST',
      body: JSON.stringify({ caseName }),
    }
    return fetchWithAuth('/api/v1/my/cases', options)
  }
  const deleteCase = caseId => {
    dispatch({payload: {loading: true, status: 'deletingcase' }})
    const options = {
      method: 'DELETE',
    }
    fetchWithAuth(`/api/v1/my/cases/${caseId}`, options)
      .then(res => {
        const { success } = res
        loadCases()
          .then(dispatch({payload: { messages: [success]}}))
      })
  }
  const dismissModal = () => dispatch({
    payload: {
      messages: null
    }
  })
  const actions = {
    createCase,
    deleteCase,
    dismissModal,
    updateCaseProfile: bindUpdateCaseProfile(dispatch)
  }
  return Object.assign({}, state, { actions })
}

export default useCases
