import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import AppButton from '../../buttons/AppButton'
import * as UI from '../styled-components'
import Grid2Columns from '../../layouts/Grid2Columns'
import Grid4Columns from '../../layouts/Grid4Columns'
import * as AppUI from '../../app'
import Section from './section'
import { child as childTemplate } from '../templates'
import { DISPLAY_PARTY_AS_OPTIONS, PARTY_1, PARTY_2 } from '../../../constants/case-parties'

const tabName = 'PARTIES'
const tabLabel =  'Parties'
const Parties = props => {
  const {
    activeTab,
    toggleActiveTab,
    party1,
    party2,
    handleSelectedParty,
    caseCaptionPartyOptions,
    children,
    dispatch
  } = props

  return (
    <Section {...{tabName, tabLabel, toggleActiveTab, activeTab}}>
      <UI.BodySectionInner>
        {/* todo: replace with correct URL */}
        <UI.BodyInstructions>For each party subject to this order, select a name from the case profile. <a href="/">Edit case profile here.</a> </UI.BodyInstructions>
        <Grid2Columns>
          <AppUI.Input>
            <label>Select from Case Profile</label>
            <select
              value={party1.caseProfileParty}
              onChange={handleSelectedParty(PARTY_1)}>
              <option value="" key="blank"> - Please select - </option>
              {caseCaptionPartyOptions.map((opt, i) => (
                <option value={opt.value} key={i}>{opt.label}</option>
              ))}
            </select>
          </AppUI.Input>

          <AppUI.Input>
            <label>Display as</label>
            <select
              value={party1.displayAs}
              onChange={e => {
                dispatch({ type: 'SET_PARTY', party: PARTY_1,
                  update: { displayAs: e.target.value }
                })
              }}
            >
              <option
                value="" key="blank"> - Please select - </option>
              {DISPLAY_PARTY_AS_OPTIONS.map((opt, i) => (
                <option value={opt.value} key={i}>{opt.label}</option>
              ))}
            </select>
          </AppUI.Input>
        </Grid2Columns>
        <Grid2Columns>
          <AppUI.Input>
            <label>Select from Case Profile</label>
            <select
              value={party2.caseProfileParty}
              onChange={handleSelectedParty(PARTY_2)}>
              <option value="" key="blank"> - Please select - </option>
              {caseCaptionPartyOptions.map((opt, i) => (
                <option value={opt.value} key={i}>{opt.label}</option>
              ))}
            </select>
          </AppUI.Input>

          <AppUI.Input>
            <label>Display as</label>
            <select
              value={party2.displayAs}
              onChange={e => {
                dispatch({ type: 'SET_PARTY', party: PARTY_2,
                  update: { displayAs: e.target.value }
                })
              }}
            >
              <option value="" key="blank"> - Please select - </option>
              {DISPLAY_PARTY_AS_OPTIONS.map((opt, i) => (
                <option value={opt.value} key={i}>{opt.label}</option>
              ))}
            </select>
          </AppUI.Input>
        </Grid2Columns>
        <br />
        <UI.BodyInstructions>Enter the names and dates of births of children that are the subject to this order.</UI.BodyInstructions>
        {children.map((item, ind) => {
          const { firstName = '', lastName = '', dateOfBirth = '' } = item
          return (
            <Grid4Columns key={ind}>
              <div>
                <AppUI.Input>
                  {ind === 0 && <label>First Name</label>}
                  <input
                    type="text"
                    placeholder="Enter first name..."
                    value={firstName}
                    onChange={e => {
                      const newItem = Object.assign({}, item, {
                        firstName: e.target.value
                      })
                      children[ind] = newItem
                      dispatch({
                        type: 'SET_CHILDREN',
                        children: [...children]
                      })
                    }}
                  />
                </AppUI.Input>
              </div>
              <div>
                <AppUI.Input>
                  {ind === 0 && <label>Last Name</label>}
                  <input
                    type="text"
                    placeholder="Enter last name..."
                    value={lastName}
                    onChange={e => {
                      const newItem = Object.assign({}, item, {
                        lastName: e.target.value
                      })
                      children[ind] = newItem
                      dispatch({
                        type: 'SET_CHILDREN',
                        children: [...children]
                      })
                    }}
                  />
                </AppUI.Input>
              </div>
              <div>
                <AppUI.Input>
                  {ind === 0 && <label>Date of Birth</label>}
                  <input
                    type="date"
                    value={dateOfBirth}
                    onChange={e => {
                      children[ind] = Object.assign({}, item, {
                        dateOfBirth: e.target.value
                      })
                      dispatch({
                        type: 'SET_CHILDREN',
                        children: [...children]
                      })
                    }}
                  />
                </AppUI.Input>
              </div>
              <div>
                <AppUI.Input>
                  {ind === 0 && <label>Remove</label>}
                  {ind === 0 && <br />}
                  <AppButton
                    onClick={() => {
                      if (1 === children.length) {
                        dispatch({ type: 'SET_CHILDREN', children: [childTemplate] })
                      } else {
                        children.splice(ind, 1)
                        dispatch({
                          type: 'SET_CHILDREN',
                          children: [...children]
                        })
                      }
                    }}
                  ><FontAwesomeIcon icon={faTimes} /></AppButton>
                </AppUI.Input>
              </div>
            </Grid4Columns>
          )
        })}
        <Grid4Columns>
          <div>
            <AppUI.Input>
              <AppButton
                onClick={() => {
                  dispatch({
                    type: 'SET_CHILDREN',
                    children: [...children, childTemplate]
                  })
                }}
              >Add Child</AppButton>
            </AppUI.Input>
          </div>
        </Grid4Columns>
      </UI.BodySectionInner>
    </Section>
  )
}

export default Parties
