import React, { useState, useReducer } from 'react'
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'
// todo: rename file since calculations are used in both A & B
// import {
//   scheduleOfBasicSupportObligations,
//   legalResponsibilityForChildrenOtherRelationship,
//   applyDocumentedPaymentsCap,
//   calculateNetWorkRelated
// } from './worksheet-a-calculations'
import AppButton from '../buttons/AppButton'
import OptionButton from '../buttons/OptionButton'
import useCase from '../../hooks/useCase'
import useAttorneyProfiles from '../../hooks/useAttorneyProfiles'
import useBuffer from '../../hooks/useBuffer'
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning'
import Loader from '../Loader'
import CaseHeader from '../CaseHeader'
import Grid3Columns from '../layouts/Grid3Columns'
import * as AppUI from '../app'
import { useSession } from '../../App'
import ModalWrapper from '../modals'
import CaseSummary from '../case-summary'

// import * as templates from './templates'
import * as Layout from '../layouts/ContentWithSidebarLeft'
import * as UI from '../child-support/styled-components'
import { ColoradoCourts } from '../../constants/ColoradoCourts'
import { Link } from 'react-router-dom'

const reducer = (state, action) => {
  //console.log(' - reducer - action - ', action)
  const { type, payload } = action
  const { profile: prevProfile } = state
  const {
    admin: prevAdmin,
    parties: prevParties,
    courtCaseInfo: prevCourtCaseInfo,
    filingPartyInfo: prevFilingPartyInfo,
    proSeAttorneyProfile: prevProSeAttorneyProfile
  } = prevProfile

  switch(type) {
  case 'setCaseName':
    const newAdmin = Object.assign({}, prevAdmin, payload)
    return Object.assign({}, state, {
      profile: Object.assign({}, prevProfile, { admin: newAdmin})
    })

  case 'setParties':
    const newParties = Object.assign({}, prevParties, payload)
    return Object.assign({}, state, {
      profile: Object.assign({}, prevProfile, { parties: newParties })
    })

  case 'setCourtCaseInfo':
    const newCourtCaseInfo = Object.assign({}, prevCourtCaseInfo, payload)
    return Object.assign({}, state, {
      profile: Object.assign({}, prevProfile, { courtCaseInfo: newCourtCaseInfo })
    })

  case 'setFilingPartyInfo':
    const newFilingPartyInfo = Object.assign({}, prevFilingPartyInfo, payload)
    return Object.assign({}, state, {
      profile: Object.assign({}, prevProfile, { filingPartyInfo: newFilingPartyInfo })
    })

  case 'setProSeAttorneyProfile':
    const newProSeAttorneyProfile = Object.assign({}, prevProSeAttorneyProfile, payload)
    return Object.assign({}, state, {
      profile: Object.assign(
        {}, prevProfile, { proSeAttorneyProfile: newProSeAttorneyProfile }
      )
    })

  default:
    return Object.assign({}, state, payload)
  }
}

const initialState = {
  status: 'initializing',
  profile: {},
  activeTab: false
}
const CaseProfile = props => {
  const { user, session } = useSession()
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    status,
    profile,
    activeTab
  } = state
  const {
    admin = {},
    parties = {},
    courtCaseInfo = {},
    filingPartyInfo = {},
    proSeAttorneyProfile = {}
  } = profile
  const {
    caseName
  } = admin
  const {
    petitioner = {},
    coPetitionerRespondent = {},
    intervenor = ''
  } = parties
  const {
    firstName: petitionerFirstName = '',
    middleName: petitionerMiddleName = '',
    lastName: petitionerLastName = '',
  } = petitioner
  const {
    type: coPetitionerRespondentType = 'Co-Petitioner',
    firstName: coPetitionerRespondentFirstName = '',
    middleName: coPetitionerRespondentMiddleName = '',
    lastName: coPetitionerRespondentLastName = '',
  } = coPetitionerRespondent
  const {
    courtName = '',
    caseNumber = '',
    additionalCaseNumber = '',
    caseType = '',
    divisionCourtroom = ''
  } = courtCaseInfo
  const {
    representation = '',
    partyRole = ''
  } = filingPartyInfo
  const {
    name: proSeName  = '',
    address1: proSeAddress1 = '',
    address2: proSeAddress2 = '',
    city: proSeCity = '',
    state: proSeState = '',
    zip: proSeZip = '',
    phone: proSePhone = '',
    fax: proSeFax = '',
    email: proSeEmail = '',
  } = proSeAttorneyProfile

  const { match } = props
  const { params } = match
  const { id } = params
  const {
    loading,
    status: caseStatus,
    profile: initialProfile,
    actions
  } = useCase(id)
  const {
    updateCaseProfile
  } = actions
  const {
    loading: attorneyProfilesLoading,
    attorneyProfiles,
    helpers
  } = useAttorneyProfiles({})
  const { representationOptions } = helpers
  const selectedRepresentation = representationOptions.find(option => representation === option.value)
  const { label: selectedRepresentationLabel } = selectedRepresentation || {}

  if ('initializing' === status && 'loaded' === caseStatus) {
    //console.log( ' - initializing profile - ',  initialProfile)
    dispatch({ payload: {
      status: 'loaded',
      profile: initialProfile
    }})
  }
  const buffering = useBuffer()
  const [ Prompt, setUnsaved, setSaved ] = useUnsavedChangesWarning()

  const setCaseName = newCaseName => dispatch({ type: 'setCaseName', payload: { caseName: newCaseName }})
  const setCourtName = newCourtName => dispatch({
    type: 'setCourtCaseInfo',
    payload: {courtName: newCourtName}
  })
  const setCaseType = newCaseType => dispatch({
    type: 'setCourtCaseInfo',
    payload: {caseType: newCaseType}
  })
  const setCaseNumber = newCaseNumber => dispatch({type: 'setCourtCaseInfo', payload: {caseNumber: newCaseNumber }})
  const setAdditionalCaseNumber = newAdditionalCaseNumber => dispatch({
    type: 'setCourtCaseInfo',
    payload: {additionalCaseNumber: newAdditionalCaseNumber }
  })
  const setDivisionCourtroom = newDivisionCourtroom => dispatch({
    type: 'setCourtCaseInfo',
    payload: {divisionCourtroom: newDivisionCourtroom }
  })
  const setPartyRole = newPartyRole => dispatch({
    type: 'setFilingPartyInfo',
    payload: { partyRole: newPartyRole }
  })
  const setRepresentation = newRepresentation => dispatch({
    type: 'setFilingPartyInfo',
    payload: { representation: newRepresentation }
  })

  const setProSeAttorneyProfile = keyName => newValue => dispatch({
    type: 'setProSeAttorneyProfile',
    payload: { [keyName]: newValue }
  })
  const [matchedList, setMatchedList] = useState([])
  const [confirmUpdateDetailsSaved, setConfirmUpdateDetailsSaved] = useState(false)
  const [updateDetailsError, setUpdateDetailsError] = useState(false)

  /*
   * todo: refactor using reducer
  const updateCaseProfileState = profile => {
    const { admin = {}, courtCaseInfo = {}, parties = {}, filingPartyInfo = {} } = profile


      //title
      const { caseName : resCaseName = '' } = admin
      setCaseName(resCaseName)

      //parties
      const {
        petitioner : partyPetitioner = '',
        coPetitioner : partyCoPetitioner = '',
        intervenor : partyIntervenor = ''
        } = parties

      const petitionerNames = partyPetitioner.split(' ')
      setCurrentPetitionerFirst(petitionerNames[0])
      setCurrentPetitionerMiddle(petitionerNames[1])
      setCurrentPetitionerLast(petitionerNames[2])

      const copetitionerNames = partyCoPetitioner.split(' ')
      setCurrentCoPetitionerOrRespondentFirst(copetitionerNames[0])
      setCurrentCoPetitionerOrRespondentMiddle(copetitionerNames[1])
      setCurrentCoPetitionerOrRespondentLast(copetitionerNames[2])

      setCurrentIntervenor(partyIntervenor)

      //court & case info
      const {
        caseNumber : infoCaseNumber = [],
        courtName : infoCourtName = '',
        caseType : infoCaseType = '',
        divisionCourtroom : infoDivisionCourtroom = ''
        } = courtCaseInfo
      setCurrentCourt(infoCourtName)
      setCaseType(infoCaseType)
      setCaseNumber(infoCaseNumber)
      setDivisionCourtroom(infoDivisionCourtroom)

      //filing party info
      const {
        representation : infoRepresentation = '',
        partyRole : infoPartyRole = ''
      } = filingPartyInfo
      setRepresentation(infoRepresentation)
      setFilingRole(infoPartyRole)
  }
  */

  const courtHandler = newValue => {
    if (newValue.length >= 3) {
      dynamicSearch(newValue.toLowerCase())
    } else {
      deleteDynamicSearch()
    }
  }

  const cleaner = (val) => {
    if (val) {
      return val
      .trim()
      .split(/ +/)
      .join(" ")
      .replace(/[^\w\s]/gi, "")
    } else {
      return
    }
  }


  const saveCaseProfileDetails = () => {
    const requestBody = {
      admin: {
        caseName: cleaner(caseName) || "",
        ownedBy: session.userId || "",
        createdBy: session.userId || ""
      },
      parties: {
        petitioner: {
          firstName: cleaner(petitionerFirstName),
          middleName: cleaner(petitionerMiddleName),
          lastName: cleaner(petitionerLastName)
        },
        coPetitionerRespondent: {
          type: coPetitionerRespondentType,
          firstName: cleaner(coPetitionerRespondentFirstName),
          middleName: cleaner(coPetitionerRespondentMiddleName),
          lastName: cleaner(coPetitionerRespondentLastName)
        },
        intervenor: cleaner(intervenor) || "",
      },
      courtCaseInfo: {
        courtName: cleaner(courtName) || "",
        caseType: caseType || "",
        caseNumber: caseNumber,
        additionalCaseNumber: additionalCaseNumber,
        divisionCourtroom: cleaner(divisionCourtroom) || ""
      },
      filingPartyInfo: {
        representation: representation || "",
        partyRole: partyRole || ""
      },
      proSeAttorneyProfile: {
        name: cleaner(proSeName),
        address1: cleaner(proSeAddress1),
        address2: cleaner(proSeAddress2),
        city: cleaner(proSeCity),
        state: cleaner(proSeState),
        zip: cleaner(proSeZip),
        phone: cleaner(proSePhone),
        fax: cleaner(proSeFax),
        email: proSeEmail
      }
    }
    updateCaseProfile(requestBody, id)
      .then(res => {
        if (!res.error) {
          setSaved()
          setConfirmUpdateDetailsSaved(true)
          //updateCaseProfileState(res)
        } else {
          setUpdateDetailsError(true)
        }
      })
  }

  const handleChange = (inputVal, update) => {
    update(inputVal)
    setUnsaved()
  }

  const toggleActiveTab = tabName => {
    dispatch({ payload: {activeTab: tabName === activeTab ? false : tabName}})
  }

  const caseHeaderProps = {
    case: { title: caseName, id },
    doc: { title: 'Case Profile' }
  }

  const dynamicSearch = searchedText => {
      const foundCourt = ColoradoCourts.filter(court => {
      return court.County.toLowerCase().includes(searchedText) || court.Name.toLowerCase().includes(searchedText) || court['Court Address'].toLowerCase().includes(searchedText)
    })
    outputHtml(foundCourt)
  }

  const deleteDynamicSearch = () => setMatchedList([])

  const outputHtml = foundCourt => {
      const html = foundCourt.map(court =>
        `${court.County}  ${court.Name}  ${court['Court Address']} `
      )
      setMatchedList(html)
  }

  return (
    <div>
      {(loading || attorneyProfilesLoading || buffering) && <Loader />}
      {Prompt}
      <CaseHeader {...caseHeaderProps} />
      <Layout.ColsMain>
        <Layout.MainCol1>
          <Layout.AsideSticky>

            <CaseSummary {...{profile}} />

            <Layout.AsideSection>
              <Layout.AsideSummary>
                <AppButton onClick={saveCaseProfileDetails}>Save</AppButton>
                <AppButton>Clear</AppButton>
              </Layout.AsideSummary>
            </Layout.AsideSection>


          </Layout.AsideSticky>
        </Layout.MainCol1>

        <Layout.MainCol2>

        <UI.ToggleSectionButton
            isActive={'ADMIN' === activeTab}
            onClick={() => toggleActiveTab('ADMIN')}>
            <UI.ToggleSectionIcon
              icon={'ADMIN' === activeTab ? faAngleDown : faAngleRight} /> Admin
          </UI.ToggleSectionButton>
          {'ADMIN' === activeTab && (
            <UI.BodySectionInner>

              <AppUI.Input>
                  <label>Case Name</label>
                  <input
                    type="text"
                    placeholder="Enter new case name..."
                    value={caseName}
                    onChange={e => {
                      handleChange(e.target.value, setCaseName)
                    }}
                  />
                </AppUI.Input>

                {/* TODO: implement along with team account support so cases can change owners
                <AppUI.Input>
                  <label>Case Owner (only the User can delete or archive a case)</label>
                  <select onChange={e => {
                    setCurrentUser(e.target.value)
                  }}>
                    <option value='User 1'>{user.displayName}</option>
                    <option value='User 2'>User 2</option>
                    <option value='User 3'>User 3</option>
                  </select>
                </AppUI.Input>
                */}

            </UI.BodySectionInner>
          )}

          <UI.ToggleSectionButton
            isActive={'PARTIES' === activeTab}
            onClick={() => toggleActiveTab('PARTIES')}>
            <UI.ToggleSectionIcon
              icon={'PARTIES' === activeTab ? faAngleDown : faAngleRight} /> Parties
            </UI.ToggleSectionButton>

          {'PARTIES' === activeTab && (
            <UI.BodySectionInner>

            <label>Petitioner</label>
              <Grid3Columns>
                <AppUI.Input>
                  <input
                    type="text"
                    placeholder="Enter Petitioner First Name..."
                    value={petitionerFirstName}
                    onChange={e => {handleChange( e.target.value, value => dispatch({
                      type: 'setParties',
                      payload: { petitioner: {
                        firstName: value,
                        middleName: petitionerMiddleName,
                        lastName: petitionerLastName
                      }}
                    }))}}
                  />
                </AppUI.Input>
                <AppUI.Input>
                  <input
                    type="text"
                    placeholder="Enter Petitioner Middle Name or Initial..."
                    value={petitionerMiddleName}
                    onChange={e => {handleChange( e.target.value, value => dispatch({
                      type: 'setParties',
                      payload: { petitioner: {
                        firstName: petitionerFirstName,
                        middleName: value,
                        lastName: petitionerLastName
                      }}
                    }))}}
                  />
                </AppUI.Input>
                <AppUI.Input>
                  <input
                    type="text"
                    placeholder="Enter Petitioner Last Name..."
                    value={petitionerLastName}
                    onChange={e => {handleChange( e.target.value, value => dispatch({
                      type: 'setParties',
                      payload: { petitioner: {
                        firstName: petitionerFirstName,
                        middleName: petitionerMiddleName,
                        lastName: value
                      }}
                    }))}}
                  />
                </AppUI.Input>
              </Grid3Columns>
              <label>Co-Petitioner / Respondent</label>
              <Grid3Columns>
              <AppUI.Input>
                  <input
                    type="text"
                    placeholder="Enter First Name..."
                    value={coPetitionerRespondentFirstName}
                    onChange={e => {handleChange( e.target.value, value => dispatch({
                      type: 'setParties',
                      payload: { coPetitionerRespondent: {
                        type: coPetitionerRespondentType,
                        firstName: value,
                        middleName: coPetitionerRespondentMiddleName,
                        lastName: coPetitionerRespondentLastName
                      }}
                    }))}}
                  />
                </AppUI.Input>
                <AppUI.Input>
                  <input
                    type="text"
                    placeholder="Enter Middle Name or Initial..."
                    value={coPetitionerRespondentMiddleName}
                    onChange={e => {handleChange( e.target.value, value => dispatch({
                      type: 'setParties',
                      payload: { coPetitionerRespondent: {
                        type: coPetitionerRespondentType,
                        firstName: coPetitionerRespondentFirstName,
                        middleName: value,
                        lastName: coPetitionerRespondentLastName
                      }}
                    }))}}
                  />
                </AppUI.Input>
                <AppUI.Input>
                  <input
                    type="text"
                    placeholder="Enter Last Name..."
                    value={coPetitionerRespondentLastName}
                    onChange={e => {handleChange( e.target.value, value => dispatch({
                      type: 'setParties',
                      payload: { coPetitionerRespondent: {
                        type: coPetitionerRespondentType,
                        firstName: coPetitionerRespondentFirstName,
                        middleName: coPetitionerRespondentMiddleName,
                        lastName: value
                      }}
                    }))}}
                  />
                </AppUI.Input>
              </Grid3Columns>
                <div>
                  <input
                    type="radio"
                    id="coPetitioner"
                    name="coPetitionerRespondentType"
                    value="Co-Petitioner"
                    checked={coPetitionerRespondentType === 'Co-Petitioner'}
                    onChange={e => {handleChange( e.target.value, value => dispatch({
                      type: 'setParties',
                      payload: { coPetitionerRespondent: {
                        type: value,
                        firstName: coPetitionerRespondentFirstName,
                        middleName: coPetitionerRespondentMiddleName,
                        lastName: coPetitionerRespondentLastName
                      }}
                    }))}}
                  />
                  <label htmlFor="coPetitionerOrRespondent"> Co-Petitioner</label>
                  <input
                    type="radio"
                    id="respondent"
                    name="coPetitionerRespondentType"
                    value="Respondent"
                    checked={coPetitionerRespondentType === 'Respondent'}
                    onChange={e => {handleChange( e.target.value, value => dispatch({
                      type: 'setParties',
                      payload: { coPetitionerRespondent: {
                        type: value,
                        firstName: coPetitionerRespondentFirstName,
                        middleName: coPetitionerRespondentMiddleName,
                        lastName: coPetitionerRespondentLastName
                      }}
                    }))}}
                  />
                  <label htmlFor="coPetitionerOrRespondent"> Respondent</label>
                </div>
                <div>
                <AppUI.Input>
                  <label>Intervenor</label>
                  <input
                    type="text"
                    placeholder="Enter Complete Intervenor Name..."
                    value={intervenor}
                    onChange={e => {handleChange( e.target.value, value => dispatch({
                      type: 'setParties',
                      payload: { intervenor: value }
                    }))}}
                  />
                </AppUI.Input>
                </div>

            </UI.BodySectionInner>
          )}

        <UI.ToggleSectionButton
                    isActive={'CourtAndCaseInformation' === activeTab}
                    onClick={() => toggleActiveTab('CourtAndCaseInformation')}>
                    <UI.ToggleSectionIcon
                      icon={'CourtAndCaseInformation' === activeTab ? faAngleDown : faAngleRight} /> Court and Case Information
                  </UI.ToggleSectionButton>
                  {'CourtAndCaseInformation' === activeTab && (
                    <UI.BodySectionInner>

                      <AppUI.Input>
                          <label>Court</label>
                          <input
                            type="text"
                            id="courtInputText"
                            placeholder="Search for county or court for selection"
                            value={courtName}
                            onChange={e => {
                              handleChange(e.target.value, setCourtName)
                              courtHandler(e.target.value)
                            }}
                          />
                          <div id="matched-list">
                            {matchedList.map(list => {
                              return <OptionButton onClick={e => {
                                setCourtName(list)
                                deleteDynamicSearch()
                              }} >{list}</OptionButton>
                            })}
                          </div>
                        </AppUI.Input>
                        <label>Case Type</label>
                        <div>
                          <input
                            type="radio"
                            id="inTheReMarriage"
                            name="caseType"
                            value="inTheReMarriage"
                            checked={caseType === 'In-re-the-Marriage-of'}
                            onChange={() => {
                              handleChange('In-re-the-Marriage-of', setCaseType)
                            }
                          }
                          />
                          <label htmlFor="inMarriageOf"> In re the Marriage of</label>
                          <br></br>
                          <input
                            type="radio"
                            id="inTheReParentalResponsibilities"
                            name="caseType"
                            value="inTheReParentalResponsibilities"
                            checked={caseType === 'In-re-the-Parental-Responsibilities-Concerning'}
                            onChange={() => {
                              handleChange('In-re-the-Parental-Responsibilities-Concerning', setCaseType)
                            }}
                          />
                          <label htmlFor="inParentalResponsibilities"> In re the Parental Responsibilities Concerning</label>
                          <br></br>
                          <input
                            type="radio"
                            id="inTheCivilUnion"
                            name="caseType"
                            value="inTheCivilUnion"
                            checked={caseType === 'In-the-Civil-Union-of'}
                            onChange={() => {
                              handleChange('In-the-Civil-Union-of', setCaseType)
                            }}
                          />
                          <label htmlFor="inCivilUnion"> In the Civil Union of</label>
                        </div>
                        <br></br>
                        <AppUI.Input>
                          <label>Case Number</label>
                          <input
                            type="text"
                            placeholder="Enter Case Number"
                            value={caseNumber}
                            onChange={e => {
                              handleChange(e.target.value, setCaseNumber)
                            }}
                          />
                        </AppUI.Input>
                        <AppUI.Input>
                          <label>Additional Case Number (if any)</label>
                          <input
                            type="text"
                            placeholder="Enter Addtitional Case Number"
                            value={additionalCaseNumber}
                            onChange={e => {
                              handleChange(e.target.value, setAdditionalCaseNumber)
                            }}
                          />
                        </AppUI.Input>
                        <AppUI.Input>
                          <label>Division / Courtroom</label>
                          <input
                            type="text"
                            placeholder="Enter Division / Courtroom"
                            value={divisionCourtroom}
                            onChange={e => {
                              handleChange(e.target.value, setDivisionCourtroom)
                            }}
                          />
                        </AppUI.Input>
                    </UI.BodySectionInner>
                  )}
          <UI.ToggleSectionButton
            isActive={'filingPartyInformation' === activeTab}
            onClick={() => toggleActiveTab('filingPartyInformation')}>
            <UI.ToggleSectionIcon
              icon={'filingPartyInformation' === activeTab ? faAngleDown : faAngleRight} /> Filing Party Information
          </UI.ToggleSectionButton>
          {'filingPartyInformation' === activeTab && (
            <UI.BodySectionInner>
                <label>Filing Party Role</label>
                <div>
                  <input
                  type="radio"
                  id="petitioner"
                  name="partyRole"
                  value="petitioner"
                  checked={partyRole === 'Petitioner'}
                  onChange={() => {
                    handleChange('Petitioner', setPartyRole)
                  }}
                  />
                  <label htmlFor="petitoner-option">Petitioner</label>
                  <br></br>
                  <input
                  type="radio"
                  id="coPetitioner"
                  name="partyRole"
                  value="coPetitioner"
                  checked={partyRole === 'Co-Petitioner'}
                  onChange={() => {
                    handleChange('Co-Petitioner', setPartyRole)
                  }}
                  />
                  <label htmlFor="coPetitioner-option">CO-Petitioner/Respondent</label>
                  <br></br>
                  <input
                  type="radio"
                  id="intervenor"
                  name="partyRole"
                  value="intervenor"
                  checked={partyRole === 'Intervenor'}
                  onChange={() => {
                    handleChange('Intervenor', setPartyRole)
                  }}
                  />
                  <label htmlFor="intervenor-option">Intervenor</label>
                </div>
                <br />
                <AppUI.Input>
                  <label>Representation</label>
                  <select onChange={e => {
                    handleChange(e.target.value, setRepresentation)
                  }}>
                    {representationOptions.map((opt, ind) => (
                      <option key={ind} value={opt.value} selected={representation === opt.value}>{opt.label}</option>
                    ))}
                  </select>
                </AppUI.Input>
                  {/* for team accts
                  <p><Link to='/account/attorney-profiles'>Click here to add or edit representation option(s).</Link></p>
                  */}
                  {/* for individual accts  */}
                  <p><Link to='/account/attorney-profiles'>Click here to edit attorney representation option.</Link></p>
                <br />
                {'Pro Se' === representation && (
                  <div>
                    <AppUI.Input>
                      <label>Name</label>
                      <input
                        type="text"
                        placeholder="Enter name..."
                        value={proSeName}
                        onChange={e => {
                          handleChange(e.target.value, setProSeAttorneyProfile('name'))
                        }}
                      />
                    </AppUI.Input>
                    <br />
                    <AppUI.Input>
                      <label>Address Line 1</label>
                      <input
                        type="text"
                        placeholder="Enter address line 1..."
                        value={proSeAddress1}
                        onChange={e => {
                          handleChange(e.target.value, setProSeAttorneyProfile('address1'))
                        }}
                      />
                    </AppUI.Input>
                    <br />
                    <AppUI.Input>
                      <label>Address Line 2</label>
                      <input
                        type="text"
                        placeholder="Enter address line 2..."
                        value={proSeAddress2}
                        onChange={e => {
                          handleChange(e.target.value, setProSeAttorneyProfile('address2'))
                        }}
                      />
                    </AppUI.Input>
                    <br />
                    <AppUI.Input>
                      <label>City</label>
                      <input
                        type="text"
                        placeholder="Enter city..."
                        value={proSeCity}
                        onChange={e => {
                          handleChange(e.target.value, setProSeAttorneyProfile('city'))
                        }}
                      />
                    </AppUI.Input>
                    <br />
                    <AppUI.Input>
                      <label>State</label>
                      <input
                        type="text"
                        placeholder="Enter state..."
                        value={proSeState}
                        onChange={e => {
                          handleChange(e.target.value, setProSeAttorneyProfile('state'))
                        }}
                      />
                    </AppUI.Input>
                    <br />
                    <AppUI.Input>
                      <label>Zip</label>
                      <input
                        type="text"
                        placeholder="Enter zip..."
                        value={proSeZip}
                        onChange={e => {
                          handleChange(e.target.value, setProSeAttorneyProfile('zip'))
                        }}
                      />
                    </AppUI.Input>
                    <br />
                    <AppUI.Input>
                      <label>Phone</label>
                      <input
                        type="text"
                        placeholder="Enter phone..."
                        value={proSePhone}
                        onChange={e => {
                          handleChange(e.target.value, setProSeAttorneyProfile('phone'))
                        }}
                      />
                    </AppUI.Input>
                    <br />
                    <AppUI.Input>
                      <label>Fax</label>
                      <input
                        type="text"
                        placeholder="Enter fax..."
                        value={proSeFax}
                        onChange={e => {
                          handleChange(e.target.value, setProSeAttorneyProfile('fax'))
                        }}
                      />
                    </AppUI.Input>
                    <br />
                    <AppUI.Input>
                      <label>Email</label>
                      <input
                        type="email"
                        placeholder="Enter email..."
                        value={proSeEmail}
                        onChange={e => {
                          handleChange(e.target.value, setProSeAttorneyProfile('email'))
                        }}
                      />
                    </AppUI.Input>
                  </div>
                )}
            </UI.BodySectionInner>
          )}

        </Layout.MainCol2>
      </Layout.ColsMain>

      {confirmUpdateDetailsSaved && (
        <ModalWrapper close={() => setConfirmUpdateDetailsSaved(false)}>
          <h2>Your Details Have Been Saved Successfully!</h2>
        </ModalWrapper>
      )}
      {updateDetailsError && (
        <ModalWrapper close={() => setUpdateDetailsError(false)}>
          <h2>There was an error updating your details!</h2>
        </ModalWrapper>
      )}
      {Prompt}
    </div>
  )
}

export default CaseProfile
