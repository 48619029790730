import { createGlobalStyle } from 'styled-components'

// global styles

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Montserrat:300,700');
  @import url('https://fonts.googleapis.com/css?family=Open+Sans');

  body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: ${props => props.theme.colors.woodsmoke};
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* base typography */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    margin: 0;
    line-height: 1.6;
  }
  h1 {
    color: ${props => props.theme.colors.blueBayoux};
    font-size: 40px;
  }
  h2 {
    font-size: 30px;
  }
  p {
    margin: 0;
  }

  /* links */
  a {
    color: ${props => props.theme.colors.hanPurple};
  }

  a:hover,
  a:active,
  a:focus {
    color: ${props => props.theme.colors.hanPurple};
  }


`

export default GlobalStyles
