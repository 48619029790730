import React from 'react'
import styled from 'styled-components'

const Text = styled.span`
  visibility: hidden;
  width: 200px;
  bottom: 140%;
  left: 50%;
  margin-left: -110px;
  background-color: ${props => props.theme.colors.gunmetal};
  color: ${props => props.theme.colors.white};
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  &::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${props => props.theme.colors.gunmetal} transparent transparent transparent;
  }
`
const Container = styled.div`
  position: relative;
  display: inline-block;
  text-align: center;
  font-weight: 900;
  min-width: 22px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.gunmetal};
  border-radius: 100%;
  margin-left: 10px;
  &:hover ${Text} {
    visibility: visible;
  }
`

const Tooltip = props => (
  <Container><span> ? </span><Text>{props.children}</Text>
  </Container>
)

export default Tooltip
