import { csvDataString } from '../data/14-10-115a-until2020'
const PapaParse = require('papaparse/papaparse.min.js');
const parseResult = PapaParse.parse(csvDataString, {
  header: true,
  transform: Number
})
const { data: scheduleData, meta: metaData } = parseResult
const rowIncrements = 50
const maxNumberOfChildrenSupported = 6

export const scheduleOfBasicSupportObligations = (monthlyGross, numberOfChildren) => {
  const applicableRow = scheduleData.find( row => {
    const income = row[metaData.fields[0]]
    return monthlyGross >= income && (monthlyGross - rowIncrements) < income
  })
  if (!applicableRow) return false
  const applicableColumn = Math.min(maxNumberOfChildrenSupported, numberOfChildren)
  if (monthlyGross === applicableRow[metaData.fields[0]]) {
    return applicableRow[metaData.fields[applicableColumn]]
  } else {
    // interpolation needed, following linear interpolation model
    const nextApplicableRow = scheduleData.find(row => {
      const income = row[metaData.fields[0]]
      return (monthlyGross + rowIncrements) >= income && monthlyGross < income
    })
    const incomeIncrementRatio = (monthlyGross - applicableRow[metaData.fields[0]]) / rowIncrements
    const obligationRange = nextApplicableRow[metaData.fields[applicableColumn]]
      - applicableRow[metaData.fields[applicableColumn]]
    const obligationIncrement = incomeIncrementRatio * obligationRange
    return applicableRow[metaData.fields[applicableColumn]] + obligationIncrement
  }
}

export const legalResponsibilityForChildrenOtherRelationship = (
  monthlyGross,
  adjustedMaintenanceReceived,
  adjustedMaintenancePaid,
  numberOfChildren
) => {
  const adjustedIncome = monthlyGross
    - (false !== adjustedMaintenanceReceived ? adjustedMaintenanceReceived : 0)
    + (false !== adjustedMaintenancePaid ? adjustedMaintenancePaid : 0)
  if (!numberOfChildren) {
    return 0
  } else if (1900 >= monthlyGross) {
    // todo: low income adjustment
    return false
  } else if (30000 < monthlyGross) {
    // todo: implement projection
    return false
  } else {
    return .75 * scheduleOfBasicSupportObligations(
      monthlyGross,
      numberOfChildren
    )
  }
}

const documentedPaymentsCap = (
  monthlyGross,
  numberOfChildren
) => {
  if (!numberOfChildren) {
    return 0
  } else if (1900 >= monthlyGross) {
    // todo: low income adjustment
    return numberOfChildren * 400
  } else if (30000 < monthlyGross) {
    // todo: implement projection
    return numberOfChildren * 400
  } else {
    return scheduleOfBasicSupportObligations(monthlyGross, numberOfChildren)
  }
}

export const applyDocumentedPaymentsCap = (
  monthlyGross,
  numberOfChildren,
  payments
) => {
  const cap = documentedPaymentsCap(monthlyGross, numberOfChildren)
  return Math.min(
    payments,
    cap
  )
}

export const calculateNetWorkRelated =
  (monthlyGross, numberOfChildren, workRelatedChildCare) => {
    const monthlyTaxCreditLimit = 1 === numberOfChildren ? 250 : 500
    const adjustmentRate = monthlyGross < 1250 ? .65
      : monthlyGross < 1417 ? .66
      : monthlyGross < 1584 ? .67
      : monthlyGross < 1750 ? .68
      : monthlyGross < 1917 ? .69
      : monthlyGross < 2084 ? .70
      : monthlyGross < 2250 ? .71
      : monthlyGross < 2417 ? .72
      : monthlyGross < 2584 ? .73
      : monthlyGross < 2750 ? .74
      : monthlyGross < 2917 ? .75
      : monthlyGross < 3083 ? .76
      : monthlyGross < 3250 ? .77
      : monthlyGross < 3417 ? .78
      : monthlyGross < 3584 ? .79
      : .80
    if (false === monthlyGross
      || false === numberOfChildren
      || false === workRelatedChildCare) {
      return false
    } else if (workRelatedChildCare <= monthlyTaxCreditLimit) {
      return workRelatedChildCare * adjustmentRate
    } else {
      return (monthlyTaxCreditLimit * adjustmentRate) + (workRelatedChildCare - monthlyTaxCreditLimit)
    }
}
