import React from 'react'

import { presentDollarAmount } from '../../../helpers/presenters'
import * as AppUI from '../../app'
import Inputs from '../../inputs'
import Grid2Columns from '../../layouts/Grid2Columns'
import Tooltip from '../../tooltips/Tooltip'
import * as UI from '../styled-components'
import Section from './section'

const tabName = 'SPOUSAL_MAINTENANCE_ADJUSTMENTS'
const tabLabel = 'Spousal Maintenance Adjustments'
const SpousalMaintenanceAdjustments = props => {
  const {
    toggleActiveTab,
    activeTab,
    party1Label,
    party2Label,
    PARTY_1,
    PARTY_2,
    adjustments1,
    adjustments2,
    spousalMaintenanceEntryMethod,
    calculatedSpousalSupport,
    directSpousalSupport,
    spousalSupportOfThisRelationshipIsTaxable,
    combinedAdjustedGrossIncomeIsAbove20k,
    extrapolateSpousalMaintenance,
    spousalMaintenanceTerm,
    spousalMaintenanceNOTREntry,
    dispatch
  } = props
  const spousalMaintenanceApplies = 'CALCULATOR' === spousalMaintenanceEntryMethod ||
    'DIRECT' === spousalMaintenanceEntryMethod
  return (
    <Section {...{tabName, tabLabel, toggleActiveTab, activeTab}}>
      <UI.BodySectionInner>
        <UI.BodySubSectionTitle>Spousal Maintenance For This Relationship</UI.BodySubSectionTitle>
        <AppUI.Input>
          <AppUI.RadioLabel>Not applicable
            <input
              name="spousalMaintenanceEntryMethod"
              value=""
              type="radio"
              checked={'' === spousalMaintenanceEntryMethod}
              onChange={e => {
                dispatch({
                  type: 'SET_SPOUSAL_MAINTENANCE_ENTRY_METHOD',
                  entryMethod: e.target.value
                })
              }}/>
            <AppUI.RadioCheckmark className="app-checkmark" />
          </AppUI.RadioLabel>
          <AppUI.RadioLabel>Apply calculated value
            <input
              name="spousalMaintenanceEntryMethod"
              value="CALCULATOR"
              type="radio"
              checked={'CALCULATOR' === spousalMaintenanceEntryMethod}
              onChange={e => {
                dispatch({
                  type: 'SET_SPOUSAL_MAINTENANCE_ENTRY_METHOD',
                  entryMethod: e.target.value
                })
              }}/>
            <AppUI.RadioCheckmark className="app-checkmark" />
          </AppUI.RadioLabel>
          <AppUI.RadioLabel>Apply value entered directly
            <input
              name="spousalMaintenanceEntryMethod"
              value="DIRECT"
              type="radio"
              checked={'DIRECT' === spousalMaintenanceEntryMethod}
              onChange={e => {
                dispatch({
                  type: 'SET_SPOUSAL_MAINTENANCE_ENTRY_METHOD',
                  entryMethod: e.target.value
                })
              }}/>
            <AppUI.RadioCheckmark className="app-checkmark" />
          </AppUI.RadioLabel>
        </AppUI.Input>
        <br />
        {'CALCULATOR' === spousalMaintenanceEntryMethod && (
          <Grid2Columns>
            {calculatedSpousalSupport.payor === PARTY_1 && (
                <UI.BodyValue><em>{party1Label}</em> pays <em>{party2Label}</em> {presentDollarAmount(calculatedSpousalSupport.maintenance)}</UI.BodyValue>
            )}
            {calculatedSpousalSupport.payor === PARTY_2 && (
              <UI.BodyValue><em>{party2Label}</em> pays <em>{party1Label}</em> {presentDollarAmount(calculatedSpousalSupport.maintenance)}</UI.BodyValue>
            )}
            {!calculatedSpousalSupport.payor && (
              <UI.BodyValue>Not applicable. Calculation results in zero maintenance</UI.BodyValue>
            )}
          </Grid2Columns>
        )}
        {'DIRECT' === spousalMaintenanceEntryMethod && (
          <div>
            <AppUI.Input>
              <label>Payor:</label>
              <AppUI.RadioLabel>{party1Label}
                <input
                name="directSpousalSupport[payor]"
                value="PARTY_1"
                type="radio"
                checked={PARTY_1 === directSpousalSupport.payor}
                onChange={e => {
                  dispatch({
                    type: 'SET_DIRECT_SPOUSAL_SUPPORT',
                    update: { payor: PARTY_1}
                  })
                }}
                />
                <AppUI.RadioCheckmark className="app-checkmark" />
              </AppUI.RadioLabel>
              <AppUI.RadioLabel>{party2Label}
                <input
                name="directSpousalSupport[payor]"
                value="PARTY_2"
                type="radio"
                checked={PARTY_2 === directSpousalSupport.payor}
                onChange={e => {
                  dispatch({
                    type: 'SET_DIRECT_SPOUSAL_SUPPORT',
                    update: { payor: PARTY_2}
                  })
                }}
                />
                <AppUI.RadioCheckmark className="app-checkmark" />
              </AppUI.RadioLabel>
            </AppUI.Input>
            <br />
            <Grid2Columns>
              <AppUI.Input>
                <label>Monthly amount:</label>
                <input
                  type="number"
                  value={directSpousalSupport.maintenance}
                  onChange={e => {
                    dispatch({
                      type: 'SET_DIRECT_SPOUSAL_SUPPORT',
                      update: { maintenance: Number(e.target.value) }
                    })
                  }}
                />
              </AppUI.Input>
            </Grid2Columns>
          </div>
        )}
        <br />
        {spousalMaintenanceApplies && <div>
          <AppUI.Input>
            <Inputs.CheckboxLabel htmlFor="spousalSupportOfThisRelationshipIsTaxable">Maintenance is taxable
              <input
                id="spousalSupportOfThisRelationshipIsTaxable"
                name="spousalSupportOfThisRelationshipIsTaxable"
                type="checkbox"
                checked={!!spousalSupportOfThisRelationshipIsTaxable}
                onChange={e => {
                  dispatch({
                    type: 'SET_SPOUSAL_SUPPORT_IS_TAXABLE',
                    isTaxable: !spousalSupportOfThisRelationshipIsTaxable
                  })
                }}/>
            </Inputs.CheckboxLabel>
          </AppUI.Input>
          <br />
          {combinedAdjustedGrossIncomeIsAbove20k && <AppUI.Input>
            <Inputs.CheckboxLabel htmlFor="extrapolateSpousalMaintenance">Extrapolate spousal maintenance<Tooltip>Apply calculation beyond guidelines limitations set by C.R.S. § 14-10-114(3.5)</Tooltip>
              <input
                id="extrapolateSpousalMaintenance"
                name="extrapolateSpousalMaintenance"
                type="checkbox"
                checked={!!extrapolateSpousalMaintenance}
                onChange={e => {
                  dispatch({
                    type: 'SET_EXTRAPOLATE_SPOUSAL_SUPPORT',
                    extrapolateSpousalMaintenance: !extrapolateSpousalMaintenance
                  })
                }}/>
            </Inputs.CheckboxLabel>
          </AppUI.Input>}
          {combinedAdjustedGrossIncomeIsAbove20k && <br />}
          <AppUI.Input>
            <label>Maintenance term:</label>
            <AppUI.RadioLabel>Enter directly
              <input
                name="spousalMaintenanceTermEntryMethod"
                value="DIRECT"
                type="radio"
                checked={"DIRECT" === spousalMaintenanceTerm.entryMethod}
                onChange={e => {
                  dispatch({
                    type: 'SET_SPOUSAL_MAINTENANCE_TERM_ENTRY_METHOD',
                    value: e.target.value
                  })
                }}/>
              <AppUI.RadioCheckmark className="app-checkmark" />
            </AppUI.RadioLabel>
            <AppUI.RadioLabel>Enter dates and use guideline
              <input
                name="spousalMaintenanceTermEntryMethod"
                value="BY_DATES"
                type="radio"
                checked={"BY_DATES" === spousalMaintenanceTerm.entryMethod}
                onChange={e => {
                  dispatch({
                    type: 'SET_SPOUSAL_MAINTENANCE_TERM_ENTRY_METHOD',
                    value: e.target.value
                  })
                }}/>
              <AppUI.RadioCheckmark className="app-checkmark" />
            </AppUI.RadioLabel>
          </AppUI.Input>
          <br />
          {"DIRECT" === spousalMaintenanceTerm.entryMethod && <div>
            <Grid2Columns>
              <div>
                <AppUI.Input>
                  <label htmlFor="spousalMaintenanceTermDirectYears">Years:</label>
                  <input
                    type="number"
                    min="0"
                    name="spousalMaintenanceTermDirectYears"
                    value={spousalMaintenanceTerm.direct.years}
                    onChange={e => dispatch({
                      type: 'SET_SPOUSAL_MAINTENANCE_TERM_DIRECT',
                      field: 'years',
                      value: e.target.value
                    })}
                    />
                </AppUI.Input>
              </div>
              <div>
                <AppUI.Input>
                  <label htmlFor="spousalMaintenanceTermDirectMonths">Months:</label>
                  <input
                    type="number"
                    min="0"
                    name="spousalMaintenanceTermDirectMonths"
                    value={spousalMaintenanceTerm.direct.months}
                    onChange={e => dispatch({
                      type: 'SET_SPOUSAL_MAINTENANCE_TERM_DIRECT',
                      field: 'months',
                      value: e.target.value
                    })}
                  />
                </AppUI.Input>
              </div>
            </Grid2Columns>
            {false !== spousalMaintenanceTerm.result.termOfMaintenance &&
              0 !== spousalMaintenanceTerm.result.termOfMaintenance && <div>
              <UI.BodyInstructions>Total maintenance term:</UI.BodyInstructions>
              <Grid2Columns>
                <UI.BodyValue>{spousalMaintenanceTerm.result.termOfMaintenance} month{1 !== spousalMaintenanceTerm.result.termOfMaintenance && 's'}</UI.BodyValue>
              </Grid2Columns>
            </div>}
          </div>}
          {"BY_DATES" === spousalMaintenanceTerm.entryMethod && <div>
            <Grid2Columns>
              <div>
                <AppUI.Input>
                  <label htmlFor="spousalMaintenanceTermByDatesStart">Marriage date:</label>
                  <input
                    type="date"
                    name="spousalMaintenanceTermByDatesStart"
                    value={spousalMaintenanceTerm.byDates.startDateInput}
                    onChange={e => dispatch({
                      type: 'SET_SPOUSAL_MAINTENANCE_TERM_BY_DATES',
                      inputField: 'startDateInput',
                      field: 'startDate',
                      value: e.target.value
                    })}
                    />
                </AppUI.Input>
              </div>
              <div>
                <AppUI.Input>
                  <label htmlFor="spousalMaintenanceTermByDatesEnd">Decree date:</label>
                  <input
                    type="date"
                    name="spousalMaintenanceTermByDatesEnd"
                    value={spousalMaintenanceTerm.byDates.endDateInput}
                    onChange={e => dispatch({
                      type: 'SET_SPOUSAL_MAINTENANCE_TERM_BY_DATES',
                      inputField: 'endDateInput',
                      field: 'endDate',
                      value: e.target.value
                    })}
                  />
                </AppUI.Input>
              </div>
            </Grid2Columns>
            {false !== spousalMaintenanceTerm.result.termOfMaintenance && <div>
              <UI.BodyInstructions>Based on {spousalMaintenanceTerm.result.monthsOfMarriage} months married, the maintenance term is:</UI.BodyInstructions>
              <Grid2Columns>
                <UI.BodyValue>{spousalMaintenanceTerm.result.termOfMaintenance} month{1 !== spousalMaintenanceTerm.result.termOfMaintenance && 's'}</UI.BodyValue>
              </Grid2Columns>
            </div>}
            {false !== spousalMaintenanceTerm.result.message && <div>
              <UI.BodyInstructions>{spousalMaintenanceTerm.result.message}</UI.BodyInstructions>
            </div>}
          </div>}
        </div>}

        <br />
        <UI.BodySubSectionTitle>Spousal Maintenance Not Of This Relationship</UI.BodySubSectionTitle>
          <AppUI.Input>
            <AppUI.RadioLabel>Not applicable
              <input
                name="spousalMaintenanceNOTREntry"
                value="false"
                type="radio"
                checked={!spousalMaintenanceNOTREntry}
                onChange={e => {
                  dispatch({
                    type: 'SET_SPOUSAL_MAINTENANCE_NOTR_ENTRY',
                    entry: e.target.value
                  })
                }}/>
              <AppUI.RadioCheckmark className="app-checkmark" />
            </AppUI.RadioLabel>
            <AppUI.RadioLabel>Enter spousal maintenance <strong>not</strong> of this relationship
              <input
                name="spousalMaintenanceNOTREntry"
                value="true"
                type="radio"
                checked={spousalMaintenanceNOTREntry}
                onChange={e => {
                  dispatch({
                    type: 'SET_SPOUSAL_MAINTENANCE_NOTR_ENTRY',
                    entry: e.target.value
                  })
                }}/>
              <AppUI.RadioCheckmark className="app-checkmark" />
            </AppUI.RadioLabel>
          </AppUI.Input>
          {spousalMaintenanceNOTREntry && (
            <div>
              <UI.BodyInstructions>Each parent's monthly maintenance paid:</UI.BodyInstructions>
              <Grid2Columns>
                <div>
                  <AppUI.Input>
                    <label>{party1Label}</label>
                    <input
                      type="number"
                      value={adjustments1.maintenancePaidNOTR}
                      onChange={e => {
                        const newMaintenancePaidNOTR = '' === e.target.value ? false : Number(e.target.value)
                        dispatch({
                          type: 'SET_ADJUSTMENTS',
                          party: PARTY_1,
                          update: { maintenancePaidNOTR: newMaintenancePaidNOTR}
                        })
                      }}
                      />
                  </AppUI.Input>
                  <AppUI.Input>
                    <Inputs.CheckboxLabel htmlFor="maintenancePaidNOTRIsTaxDeductible1">Maintenance is taxable
                    <input
                      id="maintenancePaidNOTRIsTaxDeductible1"
                      type="checkbox"
                      checked={adjustments1.maintenancePaidNOTRIsTaxDeductible}
                      onChange={e => {
                        dispatch({
                          type: 'SET_ADJUSTMENTS',
                          party: PARTY_1,
                          update: { maintenancePaidNOTRIsTaxDeductible: !adjustments1.maintenancePaidNOTRIsTaxDeductible}
                        })
                      }}
                      />
                    </Inputs.CheckboxLabel>
                  </AppUI.Input>
                </div>
                <div>
                  <AppUI.Input>
                    <label>{party2Label}</label>
                    <input
                      type="number"
                      value={adjustments2.maintenancePaidNOTR}
                      onChange={e => {
                        const newMaintenancePaidNOTR = '' === e.target.value ? false : Number(e.target.value)
                        dispatch({
                          type: "SET_ADJUSTMENTS",
                          party: PARTY_2,
                          update: { maintenancePaidNOTR: newMaintenancePaidNOTR}
                        })
                      }}
                      />
                  </AppUI.Input>
                  <AppUI.Input>
                    <Inputs.CheckboxLabel htmlFor="maintenancePaidNOTRIsTaxDeductible2">Maintenance is taxable
                      <input
                        id="maintenancePaidNOTRIsTaxDeductible2"
                        type="checkbox"
                        checked={adjustments2.maintenancePaidNOTRIsTaxDeductible}
                        onChange={e => {
                          dispatch({
                            type: 'SET_ADJUSTMENTS',
                            party: PARTY_2,
                            update: { maintenancePaidNOTRIsTaxDeductible: !adjustments2.maintenancePaidNOTRIsTaxDeductible}
                          })
                        }}
                        />
                    </Inputs.CheckboxLabel>
                  </AppUI.Input>
                </div>
              </Grid2Columns>

            </div>
          )}
      </UI.BodySectionInner>
    </Section>
  )
}
export default SpousalMaintenanceAdjustments
